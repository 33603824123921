
@media(max-width:950px){

    .service-text-section .header h1 {
        font-size: 38px;
        width: 100%;
        text-align: center;
        font-weight: 600;
        color: #000;
        position: relative;
    }

    .service-text-section .header h1 {
        font-size: 25px !important;
        width: 56%;
        text-align: center;
        font-weight: 600;
        color: #000;
        position: relative;
    }


    .service-text-section .description p span {
        font-weight: 500;
    color: #000;
    font-style: italic;
    margin-bottom: -20px;
    display: flex;
    gap: 0px;
    font-size: 14px;
    padding: 5px;
    background-color: #116ba6;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    padding-right: 11px;
    padding-left: 25px;
    position: relative;
    margin-left: 46px;
    width: 74%;
    margin-right: 65px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    }

    .service-text-section .description p span::before {
        content: "";
        position: absolute;
        left: -63px;
        border-width: 32px;
        border-style: solid;
        border-color: transparent #116ba6 #116ba6 transparent;
        top: 0px;
        display: none;

    }

    .service-text-section .description p span::after {
        content: "";
        position: absolute;
        content: "";
        position: absolute;
        right: -63px;
        border-width: 32px;
        border-style: solid;
        border-color: #116ba6 transparent transparent #116ba6;
        top: 0px;
        display: none;
    }

    .service-text-section .description p h5 {
        font-size: 15px;
        color: #000;
        font-weight: 600;
        width: 57%;
        margin-top: 16px;
        margin-bottom: 21px;
        width: 100%;
    }

}



