.service-text-section {
    margin-bottom: 90px;
    margin-top: 40px;
}


.service-text-section .header{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin-bottom: 90px;
    margin-top: 0;
    text-align: center;
}


.service-text-section .header h2{
    font-size: 16px;
    color: #000;
}


.service-text-section .header h1 {
    color: #000;
    font-size: 53px;
    font-weight: 400;
    position: relative;
    text-align: center;
    width: 56%;
}


.service-text-section .header h1 span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 50%;
    border-radius: 15px 0 15px 0;
    background-color: #116ba6;
    width: 42px;
    height: 42px;
    z-index: -1;
}



.service-text-section .content img{
    width: 100%;
    height: 364px;
    object-fit: cover;
}

.service-text-section .content h1{
    font-size: 114px;
    font-weight: 700;
    color: #000;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    margin-top: -52px;
}

.service-text-section .content h2{
    text-align: center;
    font-size: 34px;
    font-weight: 400;
    color: #868686;
    width: 50%;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}



.service-text-section .content .info{
    margin-bottom: 18px;
}

.service-text-section .content .info h3{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
}

.service-text-section .content{
    margin-bottom: 30px;
}
.service-text-section .content .info h3 i{
    width: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 17px;
    border-radius: 50%;
    background-color: #116ba6;
    font-size: 23px;
    color: #fff;
    text-align: center;
}



.service-text-section .content .info h3 i:nth-child(2){
    background-color: transparent;
    outline: none;
    color: #000;
    font-size: 18px;
}



.service-text-section .description {
    padding: 0;
    background-color: #fff;
    margin-bottom: 17px;
    box-shadow: 0px 0px 31px rgb(0 0 0 / 11%);
    transition: 0.3s;
}


.service-text-section .description.active {
    padding: 15px;
}



.service-text-section .description h4{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
    font-size: 17px;
    color: #000;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
    margin: 0;
}


.service-text-section .description p{
    font-size: 15px;
    line-height: 27px;
    color: #6b6a6a;
    height: 0;
    overflow: hidden;
    margin: 0;
    transition: 0.4s;
}


.service-text-section .description p span{
    font-weight: 500;
    color: #000;
    font-style: italic;
    margin-bottom: -20px;
    display: flex;
    gap: 3px;
    font-size: 16px;
    padding: 5px;
    background-color: #116ba6;
    width: fit-content;
    color: #fff;
    padding-right: 25px;
    padding-left: 25px;
    position: relative;
    margin-left: 36px;
}

.service-text-section .description p span::before{
    content: "";
    position: absolute;
    left: -36px;
    border-width: 19px;
    border-style: solid;
    border-color: transparent #116ba6 #116ba6 transparent;
    top: 0px;
}

.service-text-section .description p h5{
    font-size: 15px;
    color: #000;
    font-weight: 600;
    width: 57%;
    margin-top: 16px;
    margin-bottom: 21px;
}


.service-text-section .description p h6{
    font-size: 17px;
    font-weight: 500;
    color: #499d77;
}


.service-text-section .description p span::after{
    content: "";
    position: absolute;
    content: "";
    position: absolute;
    right: -36px;
    border-width: 19px;
    border-style: solid;
    border-color: #116ba6 transparent transparent #116ba6;
    top: 0px;
}



.service-text-section .description p.active{
    height: 100%;
    padding-top: 12px;
};


