.about{
    margin-top: 80px;
    margin-bottom: 80px;
}

.about-page{
    margin-top: 63px;
    margin-bottom: 100px;
}

.about-page .about-images {
    position: relative;
    height: 541px;
}

.about-page .about-images .large-image img {
    width: 73%;
    height: 535px;
    border-radius: 0;
    object-fit: cover;
}

.about-page .about-images .medium-image img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 263px;
    object-fit: cover;
    border-radius: 0;
    border: 10px solid #fff;
    border-radius: 0;
}

.about-page .about-images .small-image{
    width: 23%;
    position: absolute;
    top: 0;
    right: 0;
    height: 170px;
    border-radius: 0;
    object-fit: cover;
}

.about-page .about-images .small-image img{
    width: 100%;
    position: absolute;
    height: 170px;
    border-radius: 0;
    object-fit: cover;
}

.about-page .about-images .small-image .text{
    position: absolute;
    z-index: 1;
    padding: 7px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: rgb(5 77 59);
    background: linear-gradient(0deg, rgb(73 157 119) 0%, rgba(0,0,0,0.40948879551820727) 100%);
    border-radius: 0;
    width: 100%;
}

.about-page .about-images .small-image .text h1{
    color: #fff;
}



.about-page .about-images .small-image .text h2{
    font-size: 14px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    line-height: 25px;
}





.about-page .about-content h2{
    font-size: 16px;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}



.about-page .about-content h1{
    margin-top: 10px;
    font-size: 33px;
    font-weight: 400;
    color: #000;
    margin-bottom: 23px;
    margin-top: 30px;
    margin-bottom: 55px;
}


.about-page .about-content p {
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    color: #000000;
    width: 80%;
}

.about-page .about-content .benefits{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 43px;
    margin-bottom: 40px;
}

.about-page .about-content .benefits img{
    width: 200px;
    height: 140px;
    border-radius: 0;
    object-fit: cover;
}

.about-page .about-content .benefits ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 128px;
    margin-right: 30px !important;
    gap: 10px;
}


.about-page .about-content .benefits ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 16px;
    font-weight: 500;
}

.about-page .about-content .benefits ul li i {
    color: #054d3b;
    font-size: 23px;
}


.about-page .about-content a{
    margin-top: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 55px;
    color: #fff;
    background-color: #054d3b;
    border-radius: 0;
    gap: 9px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 0;
    margin-top: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 179px;
    height: 42px;
    color: #fff;
    background-color: #2579b5;
    border-radius: 0;
    gap: 9px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 0;
}



.partners{
    margin-top: 50px;
    margin-bottom: 100px;
}

.partners .partners-content{
    direction: rtl;
}

.partners .partners-content h2{
    color: #054d3b;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}



.partners .partners-content h1{
    margin-top: 10px;
    font-size: 40px;
    font-weight: 400;
    color: #000;
    margin-bottom: 23px;
    margin-top: 32px;
}

.partners .partners-content h1 span{
    color: #054d3b;
}

.partners .images{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
}


.partners .images img{
    width: 24%;
    height: 100px;
    object-fit: contain;
    margin-bottom: 28px;
    opacity: 0.5;
}
