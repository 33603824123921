

.services .service-text-main {
    position: relative;
    height: 450px;
    margin-bottom: 38px;
}


.services .service-text-main>img{
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    opacity: 0.4;
}

.services  .row{
    padding: 0;
    width: 100%;
    margin: 0;
}


.services .service-text-main .service-text{
    position: relative;
    z-index: 4;
    color: #000;
    min-height: 340px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px;
    padding-top: 0;
}


.services .service-text-main .service-text h2{
    font-size: 15px;
    color: #000;
}

.services .service-text-main .service-text h1 {
    font-size: 56px;
    font-weight: 400;

    position: relative;
}

.services .service-text-main .service-text h1 span{
    align-items: center;
    background-color: #116ba6;
    border-radius: 15px 0 15px 0;
    display: flex;
    flex-direction: row;
    height: 42px;
    justify-content: center;
    left: -10px;
    position: absolute;
    top: 0;
    width: 42px;
    z-index: -1;
}

.services .service-text-main .service-text p{
    font-size: 17px;
    font-weight: 300;
    line-height: 36px;
}

.services .service{
    margin-top: -115px;
    z-index: 2;
    position: relative;
    padding: 20px;
    position: relative;
    width: 470px;
    margin-bottom: 49px;
}


.services .service img{
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.services .service h1{
    width: 91%;
    min-height: 58px;
    margin: 0;
    box-shadow: 0px 19px 46px rgb(0 0 0 / 12%);
    padding: 15px;
    font-size: 19px;
    margin-top: -20px;
    z-index: 3;
    position: relative;
    background-color: #fff;
    line-height: 25px;
}






.services .service i{
    position: absolute;
    bottom: 78px;
    right: calc(12% - -2px);
    width: 75px;
    height: 63px;
    background-color: #116ba6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 41px;
}

.services .services-slider .buttons{
    margin-bottom: 33px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.services .services-slider .buttons button{
    width: 60px;
    height: 60px;
    background-color: #116ba6;
    color: #fff;
    font-size: 21px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
}

.services .services-slider .swiper-slide{
    width: fit-content;
}






@media(max-width:950px){
    .services .service-text-main .service-text h1 {
        font-size: 31px;
        font-weight: 400;
    }


    .services .service-text-main .service-text h1 {
        font-size: 27px ;
        font-weight: 400;
    }
    .services .service-text-main .service-text {
        position: relative;
        z-index: 4;
        color: #000;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 30px;
        padding-top: 0;
        margin-top: 23px;
    }


    .services .service {
        margin-top: -2px;
        z-index: 2;
        position: relative;
        padding: 20px;
        position: relative;
        width: 100%;
        user-select: none;
    }

    .services .services-slider .swiper-slide{
        width: 100%;
    }


    .services .service i {
        position: absolute;
        bottom: 75px;
        right: calc(14% - -2px);
        width: 60px;
        height: 57px;
        background-color: #116ba6;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 35px;
    }

    .services .service h1 {
        width: 91%;
        min-height: 36px;
        margin: 0;
        box-shadow: 0px 19px 46px rgb(0 0 0 / 12%);
        padding: 15px;
        font-size: 14px;
        margin-top: -20px;
        z-index: 3;
        position: relative;
        background-color: #fff;
        line-height: 25px;
    }

    .services .service img {
        width: 100%;
        height: 183px;
        object-fit: cover;
    }



    
    .service-text-section .header h1 {
        font-size: 38px;
        width: 100%;
        text-align: center;
        font-weight: 600;
        color: #000;
        position: relative;
    }

    .service-text-section .header h1 {
        font-size: 25px !important;
        width: 56%;
        text-align: center;
        font-weight: 600;
        color: #000;
        position: relative;
    }


    .service-text-section .description p span {
        font-weight: 500;
    color: #000;
    font-style: italic;
    margin-bottom: -20px;
    display: flex;
    gap: 0px;
    font-size: 14px;
    padding: 5px;
    background-color: #116ba6;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    padding-right: 11px;
    padding-left: 25px;
    position: relative;
    margin-left: 46px;
    width: 74%;
    margin-right: 65px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    }

    .service-text-section .description p span::before {
        content: "";
        position: absolute;
        left: -63px;
        border-width: 32px;
        border-style: solid;
        border-color: transparent #116ba6 #116ba6 transparent;
        top: 0px;
        display: none;

    }

    .service-text-section .description p span::after {
        content: "";
        position: absolute;
        content: "";
        position: absolute;
        right: -63px;
        border-width: 32px;
        border-style: solid;
        border-color: #116ba6 transparent transparent #116ba6;
        top: 0px;
        display: none;
    }

    .service-text-section .description p h5 {
        font-size: 15px;
        color: #000;
        font-weight: 600;
        width: 57%;
        margin-top: 16px;
        margin-bottom: 21px;
        width: 100%;
    }

    
}