.landing {
    min-height: 600px;
    padding-top: 154px;
    position: relative;

}


.landing .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #000000c2;
    z-index: 1;
    background: rgb(7,9,21);
    background: linear-gradient(0deg, rgba(7,9,21,1) 0%, rgba(84,183,136,1) 100%);
    opacity: 0.8;
}

.landing .landing-img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landing .logo-arrow{
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    opacity: 0.05;
    height: 100%;
}

.landing .landing-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 333px;
    position: relative;
    text-align: center;
    z-index: 4;
}
.landing .landing-content h3{
    font-size: 14px;
    color: #fff101;
    text-transform: uppercase;
    margin-bottom: 11px;
}
.landing .landing-content h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 16px;
    width: 58%;
}

.landing .landing-content h1 img{
    width: 58px;
    filter: invert(1);
}

.landing .landing-content h4{
    position: relative;
    width: 110px;
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: rotate(-46deg);
}




.landing .landing-content h4 img{
    width: 50px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: invert(1);
    color: #fff;
}

.landing .landing-content i{
    color: #fff;
    font-size: 32px;
}


@media(max-width:950px){
    .landing .landing-content h1 {
        font-size: 46px;
        color: #fff;
        width: 100%;
        font-weight: 800;
        margin-bottom: 42px;
    }
}