.knowus{
    margin-bottom: 150px;
    margin-top: 82px;
}

.knowus .knowus-images{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.knowus .knowus-images .experience{
    background-color: #499d77;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 32px;
    position: absolute;
    top: -35px;
    left: 0;
    gap: 18px;
    border-radius: 15px 15px 0 15px;
}

.knowus .knowus-images .experience i{
    font-size: 77px;
}

.knowus .knowus-images .experience div{
    text-align: center;
}

.knowus .knowus-images .experience div h1{
    font-size: 52px;
    font-weight: 400;
}


.knowus .knowus-images .experience div h2{
    font-size: 15px;
}

.knowus .knowus-images .circle{
    z-index: -1;
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: -79px;
    left: 47%;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: #fbed01;
}


.knowus .knowus-images .big-img{
    width: 62%;
    height: 550px;
    object-fit: cover;
}


.knowus .knowus-images .small-img{
    width: 400px;
    height: 250px;
    object-fit: cover;
    position: absolute;
    right: 0;
    bottom: -128px;
    border: 11px solid #fff;
}


.knowus .knowus-text h2{
    font-size: 18px;
    color: #000;
}

.knowus .knowus-text h1{
    font-size: 51px;
    width: 90%;
    font-weight: 400;
    color: #000;
    margin-top: 15px;
    margin-bottom: 38px;
    position: relative;
}

.knowus .knowus-text h1 span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: -10px;
    border-radius: 15px 0 15px 0;
    background-color: #499d77;
    width: 42px;
    height: 42px;
    z-index: -1;
}

.knowus .knowus-text p{
    font-size: 17px;
    line-height: 27px;
    color: #6b6a6a;
}


.knowus .knowus-text .text{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 26px;
    margin-top: 37px;
    margin-bottom: 40px;
}


.knowus .knowus-text .text i{
    background-color: #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 72px;
    height: 63px;
    font-size: 35px;
    justify-content: center;
    color: #499d77;
    border-radius: 15px 0 15px 0;
}

.knowus .knowus-text .text .info{
    width: 100%;
}

.knowus .knowus-text .text .info h3{
    font-size: 21px;
    font-weight: 500;
    color: #000;
}

.knowus .knowus-text .text .info p{
    font-size: 17px;
    line-height: 27px;
    color: #6b6a6a;
}

.knowus .knowus-text a{
    width: 250px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #499d77;
    color: #fff;
    transition: 0.3s;
}


@media(max-width:950px){
    .knowus .knowus-images .small-img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        position: absolute;
        right: 0;
        bottom: -31px;
        border: 11px solid #fff;
    }

    .knowus .knowus-images {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 125px;
    }

    .knowus .knowus-text h1 {
        font-size: 31px;
        width: 90%;
        font-weight: 400;
        color: #000;
        margin-top: 15px;
        margin-bottom: 38px;
        position: relative;
    }
}



