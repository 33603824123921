.benefits {
    padding-top: 70px;
    margin-bottom: 150px;
}


.benefits .benefit-text h2{
    font-size: 17px;
    text-transform: uppercase;
}

.benefits .benefit-text h1{
    font-size: 50px;
    font-weight: 400;
    color: #000;
    margin-bottom: 60px;
    position: relative;
}

.benefits .benefit-text h1 span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: -10px;
    border-radius: 15px 0 15px 0;
    background-color: #499d77;
    width: 42px;
    height: 42px;
    z-index: -1;
}

.benefits .benefit-text h3{
    font-size: 19px;
    font-weight: 400;
    color: #000;
}

.benefits .benefit-text p{
    
    font-size: 17px;
    line-height: 27px;
    color: #6b6a6a;
}


.benefits .benefit-text .benefits-content{
    margin-top: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 140%;
    background-color: #fff;
    z-index: 1;
    position: relative;
    min-height: 153px;
    border: 11px solid #499d77;
}


.benefits .benefit-text .benefits-content .benefit{
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.benefits .benefit-text .benefits-content .benefit i{
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #499d77;
    color: #fff;
    font-size: 32px;
    border-radius: 50%;
}

.benefits .benefit-text .benefits-content .benefit h4{
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    width: 62%;
}

.benefits .benefit-img{
    position: relative;
}

.benefits .benefit-img img{
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.benefits .benefit-img h4{
    position: relative;
    width: 110px;
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    position: absolute;
    top: -58px;
    left: -69px;
}

.benefits .benefit-img h4 img{
    width: 50px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: initial;
    color: #000;
}

.benefits .benefit-img h4 i{
    color: #000;
    font-size: 32px;
}


@media(max-width:950px){
    .benefits .benefit-text .benefits-content {
        margin-top: 66px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #fff;
        z-index: 1;
        position: relative;
        min-height: 153px;
        border: 11px solid #499d77;
        flex-wrap: wrap;
        gap: 50px;
        align-items: center;
        justify-content: center;
        padding: 18px;
        margin-bottom: 34px;
    }

    .benefits .benefit-text .benefits-content .benefit {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}