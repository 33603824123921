/* ScrollToTopButton.css */

/* Style for the scroll-to-top button */
.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    color: #007bff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    z-index: 10;
}

/* Style for the scroll percentage circle */
.scroll-percentage-tracker {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 60px;
    height: 60px;
    border: 2px solid #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.3s;
    background-color: #fff;
    z-index: 9;

  }
  
  .scroll-percentage-fill {
    width: 100%;
    height: 100%;
    background-color: #007bff;
    transform-origin: bottom;
    transition: transform 0.3s;
    z-index: 9;

  }