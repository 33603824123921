.upper-nav{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    padding-top: 16px;
    padding-bottom: 8px;
}

.upper-nav .left{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}


.upper-nav .left a{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}

.upper-nav .left a i{
    color: #0c6aad;
}

.upper-nav .right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 28px;
}

.upper-nav .right a{
    color: #fff;
    font-size: 19px;
}

@media(max-width:950px){
    .upper-nav .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 28px;
        margin-top: 16px;
    }
}