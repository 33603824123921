.ad{
    margin-top: 90px;
    margin-bottom: 90px;
    min-height: 450px;
    position: relative;
}


.ad .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #000000bd;
    z-index: 1;
}


.ad .ad-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 430px;
    text-align: center;
    align-items: center;
    line-height: 38px;
}
.ad>img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ad h1{
    position: relative;
    z-index: 2;
    padding: 50px;
    width: 80%;
    color: #fff;
    font-size: 37px;
    font-weight: 400;
}

.ad .ad-content img{
    z-index: 2;
    position: relative;
    width: 300px;
}


.ad a{
    width: 250px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #116ba6;
    color: #fff;
    transition: 0.3s;
    position: relative;
    z-index: 3;
    margin: 50px;
}


@media(max-width:950px){
    .ad h1 {
        position: relative;
        z-index: 2;
        padding: 50px;
        width: 100%;
        color: #fff;
        font-size: 21px;
        font-weight: 400;
        line-height: 34px;
    }
}