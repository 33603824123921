.numbers{
    margin-top: 120px;
    margin-bottom: 120px;
}

.numbers .header{
    margin-top: 60px;
    margin-bottom: 90px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.numbers .header h2{
    font-size: 16px;
    color: #000;
}

.numbers .header h1{
    font-size: 54px;
    width: 56%;
    text-align: center;
    font-weight: 400;
    color: #000;
    position: relative;
}


.numbers .header h1 span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 50%;
    border-radius: 15px 0 15px 0;
    background-color: #499d77;
    width: 42px;
    height: 42px;
    z-index: -1;
}

.numbers .service-number{
    position: relative;
}

.numbers .service-number img{
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.numbers .service-number .text{
    position: absolute;
    bottom: 0;
    padding: 30px;
    border-radius: 0 25px 151px 0;
    background-color: #116ba685;
    border: 0;
    width: 86%;
    border-style: solid;
    border-color: #116ba600;
    height: 63%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}


.numbers .service-number .text a{
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    font-size: 26px;
    margin-bottom: 15px;
}

.numbers .service-number .text h3{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-transform: lowercase;
}


.numbers .service-number .text h1{
    font-size: 22px;
    font-weight: 400;
    color: #fff;
}

.numbers .numbers-content {
    background-color: #499d77;
    padding-top: 90px;
    margin-top: -99px;
    padding-bottom: 90px;
    margin-top: 30px;
}

.numbers .numbers-content .content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.numbers .numbers-content .content .number{
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    border-right: 1px solid #ffffff4a;
}


.numbers .numbers-content .content .number i{
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    font-size: 41px;
    color: #2579b5;
    border: 5px solid #0000003d;
}


.numbers .numbers-content .content .number h4{
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 52px;
    font-weight: 400;
    color: #fff;
}

.numbers .numbers-content .content .number h5{
    font-size: 16px;
    color: #fff;
}

@media(max-width:950px){
    .numbers .header h1 {
        font-size: 37px;
        width: 100%;
        text-align: center;
        font-weight: 400;
        color: #000;
        position: relative;
    }

    .numbers .numbers-content .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 43px;
    }


    .numbers .numbers-content .content .number {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 150px;
        border-right: 1px solid #ffffff4a;
    }
}