nav{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 3;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000;
    border-bottom: 3px solid #fbed01;
}

nav .nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
}


nav .nav-content .logo img{
    width: 210px;
}




nav .nav-content .nav-elements {
    display: flex;
    flex-direction: row;
    gap: 0%;
    justify-content: space-between;
    width: 100%;
    margin-left: 5%;
}

nav .nav-content .nav-elements ul{
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 21px;
    width: 100%;
    margin: 0;
    padding: 0;
}


nav .nav-content .nav-elements ul li a{
    color: #fff;
    font-size: 14px;
}

nav .nav-content .nav-elements .phone{
    display: flex;
    align-items: center;
    gap: 19px;
    padding-right: 30px;
    padding-left: 30px;
    border-left: 1px solid #ffffff21;
}

nav .nav-content .nav-elements .phone i{
    font-size: 39px;
    color: #fff;
}

nav .nav-content .nav-elements .phone>a h6{
    font-size: 14px;
    color: #fbed01;
}

nav .nav-content .nav-elements .phone>a a{
    color: #fff;
    font-weight: 400;
    font-size: 15px;
}



#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #fff;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .nav-elements {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 15015;
    }

    nav .nav-content .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
        margin: 0 !important;

    }

    
    nav .nav-content .nav-elements{
        display: none;
    }

    nav .nav-content .nav-elements ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }

    nav {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 3;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #ffffff21;
    }

    nav .nav-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
    nav.active{
        position: inherit !important;
    }

}

